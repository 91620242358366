import React from 'react';
import HeaderBar from './components/HeaderBar/HeaderBar';

function App() {
  return (
    <div>
      <HeaderBar />
    </div>
  );
}

export default App;
